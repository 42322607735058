<template>
  <div class="newAttivita" style="height:100%;">
    <v-layout justify-center align-center style="height:100%;">
      <v-flex xs11 md8 lg5>
          <v-toolbar color="secondary">
              <v-toolbar-title class="white--text">
                  Nuova Attività
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn small icon @click="$router.back()" color="white" class="ml-3 mr-1">
                <v-icon small>fas fa-times</v-icon>
              </v-btn>
          </v-toolbar>
          <v-card outlined>
              <v-form v-model="formValid" ref="form_data" lazy-formValidation>
                  <v-container>
                      <v-layout column>
                          <!--v-text-field
                              autocomplete="off"
                              label="Titolo"
                              v-model="titolo"
                              required
                              :rules="testoRules"
                          ></v-text-field-->
                          <v-select
                              :items="['Baggi','LaCelle']"
                              v-model="appartenenza"
                              v-if="ruolo == 'Master' || ruolo == 'Tecnico'"
                              required
                              filled
                              hide-details="auto"
                              :rules="requiredRules"
                              label="Appartenenza"
                              class="mb-2"
                          ></v-select>
                          <v-textarea
                              autocomplete="off"
                              label="Note"
                              class="mt-2"
                              v-model="note"
                              outlined
                              auto-grow
                              rows="1"
                          ></v-textarea>
                          <v-select
                              :items="lista_tipi_attivita"
                              v-model="tipo_attivita"
                              hide-details="auto"
                              required
                              outlined
                              :rules="requiredRules"
                              label="Tipo Attività"
                              class="mb-0"
                          ></v-select>
                          <v-container fluid>
                            <v-row>
                              <v-col cols="12" sm="4" class="py-0">
                                <v-checkbox label="Informa Utente" dense v-model="informaUtente"></v-checkbox>
                              </v-col>
                              <v-col cols="12" sm="4" class="py-0">
                                <v-checkbox label="Visibile a Utente" dense v-model="visibilitaUtente"></v-checkbox>
                              </v-col>
                            </v-row>
                          </v-container>
                          <v-select
                              :items="lista_clienti"
                              v-model="cliente"
                              required
                              outlined
                              :rules="requiredRules"
                              label="Cliente"
                              class="mb-0"
                              no-data-text="Nessun Cliente"
                              item-text="nominativo1"
                              item-value="_id"
                          ></v-select>
                          <v-select
                              :items="lista_stabilimenti"
                              v-model="stabilimento"
                              required
                              outlined
                              :rules="requiredRules"
                              label="Stabilimento"
                              class="mb-0"
                              no-data-text="Nessuno Stabilimento"
                              item-text="indirizzo"
                              item-value="_id"
                          ></v-select>
                          <v-select
                              :items="lista_tecnici"
                              v-model="assegnazione"
                              required
                              outlined
                              label="Assegnazione"
                              multiple
                              class="mb-3"
                              hide-details="auto"
                              no-data-text="Nessun Tecnico"
                              item-text="nominativo1"
                              item-value="_id"
                          ></v-select>
                          <v-menu
                            ref="menuDataInizio"
                            v-model="menuDataInizio"
                            :close-on-content-click="false"
                            :return-value.sync="data_inizio"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                autocomplete="off"
                                v-model="data_inizio"
                                label="Data Inizio"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="data_inizio"
                              no-title
                              scrollable
                              locale="it"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="menuDataInizio = false"
                              >
                                Chiudi
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.menuDataInizio.save(data_inizio)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                          <v-menu
                            ref="menuDataScadenza"
                            v-model="menuDataScadenza"
                            :close-on-content-click="false"
                            :return-value.sync="data_scadenza"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                autocomplete="off"
                                v-model="data_scadenza"
                                label="Data Scadenza"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              />
                            </template>
                            <v-date-picker
                              v-model="data_scadenza"
                              no-title
                              scrollable
                              locale="it"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="menuDataScadenza = false"
                              >
                                Chiudi
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.menuDataScadenza.save(data_scadenza)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                      </v-layout>
                  </v-container>
              </v-form>
              <v-card-actions>
                <v-container grid-list-sm fluid>
                  <v-row>
                    <v-col cols="12" md="4" offset-md="4" sm="12" class="pt-0">
                      <v-btn color="primary" width="100%" @click="submit" :disabled="!formValid || loading">Aggiungi</v-btn> 
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
          </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import apiUtenti from '@/js/pages/utenti';
import apiStabilimenti from '@/js/pages/stabilimenti';
import apiAttivita from '@/js/pages/attivita';
import { testoRules, requiredRules } from '@/js/validationRules';

export default {
  name: 'newAttivita',
  data() {
    return {
      token: this.$cookies.get('token'),
      ruolo: this.$store.state.ruolo,
      titolo: '',
      note: '',
      appartenenza: 'Baggi',
      informaUtente: false,
      visibilitaUtente: false,
      cliente: undefined,
      stabilimento: undefined,
      lista_clienti: [],
      lista_stabilimenti: [],
      formValid: false,
      testoRules: testoRules,
      requiredRules: requiredRules,
      loading: false,
      lista_permessi: ['Master', 'Tecnico', 'Partner', 'Cliente'],
      lista_tipi_attivita: ['Manutenzione', 'Posa', 'Controllo', 'Altro'],
      tipo_attivita: '',
      menuDataInizio: false,
      data_inizio: '',
      menuDataScadenza: false,
      data_scadenza: '',
      lista_tecnici: [],
      assegnazione: undefined
    }
  },
  watch: {
    async cliente() {
      this.lista_stabilimenti = await apiStabilimenti.getAll(this.token, this.cliente);
      if(
        this.lista_stabilimenti &&
        this.lista_stabilimenti.length === 1
      ) this.stabilimento = this.lista_stabilimenti[0];
    },
    ruolo() {
      if(this.ruolo == 'Partner') {
        this.appartenenza = 'Baggi';
        this.lista_permessi = ['Cliente'];
      }
    }
  },
  async created() {
    if(this.ruolo == 'Partner') {
      this.appartenenza = 'Baggi';
      this.lista_permessi = ['Cliente'];
      this.lista_clienti = await apiUtenti.getAllClientPartner(this.token, 'Baggi');
    } else this.lista_clienti = await apiUtenti.getAll(this.token, 'Cliente');

    let arr_tecnici = await apiUtenti.getAll(this.token, 'Tecnico');
    let arr_master = await apiUtenti.getAll(this.token, 'Master');
    let arr = [];
    if(
      arr_tecnici &&
      arr_tecnici.length
    ) Array.prototype.push.apply(arr, arr_tecnici);
    if(
      arr_master &&
      arr_master.length
    ) Array.prototype.push.apply(arr, arr_master);
    this.lista_tecnici = arr;
  },
  methods: {
    async submit(){
      this.loading = true;
      if(
        this.$refs.form_data.validate() &&
        this.formValid
      ){
        let data = {
          //titolo: this.titolo,
          appartenenza: this.appartenenza,
          tipo_attivita: this.tipo_attivita,
          informa_utente: this.informaUtente,
          visibilita_utente: this.visibilitaUtente,
          note: this.note,
          data_inizio: this.data_inizio,
          data_scadenza: this.data_scadenza,
          id_cliente: this.cliente,
          id_stabilimento: this.stabilimento,
          assegnazione: this.assegnazione
        };
        await apiAttivita.insertNew(this.token, data);
        this.$router.push('/listAttivita');
      }
      this.loading = false;
    },
  },
}
</script>
